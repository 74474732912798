import React from 'react';

const InstaFilled = ({width="24px", height="24px", ...props}:React.SVGProps<SVGSVGElement>) => {
    return (
        <svg width={width} height={height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M0 12C0 5.37258 5.37258 0 12 0H38C44.6274 0 50 5.37258 50 12V38C50 44.6274 44.6274 50 38 50H12C5.37258 50 0 44.6274 0 38V12ZM25 34.7778C30.4001 34.7778 34.7778 30.4001 34.7778 25C34.7778 19.5999 30.4001 15.2222 25 15.2222C19.5999 15.2222 15.2222 19.5999 15.2222 25C15.2222 30.4001 19.5999 34.7778 25 34.7778ZM25 37.7778C32.057 37.7778 37.7778 32.057 37.7778 25C37.7778 17.943 32.057 12.2222 25 12.2222C17.943 12.2222 12.2222 17.943 12.2222 25C12.2222 32.057 17.943 37.7778 25 37.7778ZM38.8889 15.5556C40.7298 15.5556 42.2222 14.0632 42.2222 12.2222C42.2222 10.3813 40.7298 8.88889 38.8889 8.88889C37.0479 8.88889 35.5556 10.3813 35.5556 12.2222C35.5556 14.0632 37.0479 15.5556 38.8889 15.5556Z" fill="black"/>
        </svg>
    )
}

export default InstaFilled;


